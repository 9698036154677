import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { WorkHeaderBase } from './base'
import { countryCodes } from '../../../config/countries'

export const WorkHeaderUAE = ({ linkColor, buttonColor }) => {
  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx(filter: {frontmatter: {slug: {eq: "work"}, country: {eq: "uae"}}}) {
        nodes {
          frontmatter {
            country
            slug
            work_header_section {
              title
              subtitle
              store_list {
                link
                title
                store_image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              partners_title
              partners {
                partner_image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                alt
              }
              background_image {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
              background_image_mobile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  `)


  const headerSection = allMdx.nodes[0].frontmatter.work_header_section

  return (
    <WorkHeaderBase
      headerSection={headerSection}
      linkColor={linkColor}
      buttonColor={buttonColor}
      country={countryCodes.UAE}
    />
  )
}
