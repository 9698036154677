export const languageCodes = {
  EN: 'en',
  EL: 'el',
  AR: 'ar'
}

export const supportedLanguageCodes = new Set([languageCodes.EN, languageCodes.EL, languageCodes.AR])

export const defaultLanguageCode = languageCodes.EN

export const languagesData = {
  shortTitle: {
    [languageCodes.EN]: 'EN',
    [languageCodes.EL]: 'ΕΛ',
    [languageCodes.AR]: 'اَلْعَرَبِيَّةُ'
  },
  longTitle: {
    [languageCodes.EN]: 'English',
    [languageCodes.EL]: 'Ελληνικά',
    [languageCodes.AR]: 'اَلْعَرَبِيَّةُ'
  },
}
