import React from 'react'
import { useTranslation } from 'react-i18next'
import { useStaticQuery, graphql } from 'gatsby'

import './index.sass'

export const AboutStatistics = ({country}) => {
  const { t } = useTranslation()

  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx(filter: {frontmatter: {slug: {eq: "about"}}}) {
        nodes {
          frontmatter {
            country
            about_statistics_section {
              list {
                amount
                title
              }
            }
          }
        }
      }
    }
  `)

  const statsSection = allMdx.nodes.find(node => node.frontmatter.country === country).frontmatter.about_statistics_section

  return (
    <div className="about-statistics">
      {statsSection.list.map(statItem => (
        <div className="stat-item" key={statItem.title}>
          <span className="stat-amount">{t(statItem.amount)}</span>
          <span className="stat-title">{t(statItem.title)}</span>
        </div>
      ))}
    </div>
  )
}
