exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-getstarted-js": () => import("./../../../src/pages/getstarted.js" /* webpackChunkName: "component---src-pages-getstarted-js" */),
  "component---src-pages-hire-baristas-js": () => import("./../../../src/pages/hire-baristas.js" /* webpackChunkName: "component---src-pages-hire-baristas-js" */),
  "component---src-pages-hire-bartenders-js": () => import("./../../../src/pages/hire-bartenders.js" /* webpackChunkName: "component---src-pages-hire-bartenders-js" */),
  "component---src-pages-hire-e-pickers-and-packers-js": () => import("./../../../src/pages/hire-e-pickers-and-packers.js" /* webpackChunkName: "component---src-pages-hire-e-pickers-and-packers-js" */),
  "component---src-pages-hire-js": () => import("./../../../src/pages/hire.js" /* webpackChunkName: "component---src-pages-hire-js" */),
  "component---src-pages-hire-waiters-js": () => import("./../../../src/pages/hire-waiters.js" /* webpackChunkName: "component---src-pages-hire-waiters-js" */),
  "component---src-pages-hire-warehouse-staff-js": () => import("./../../../src/pages/hire-warehouse-staff.js" /* webpackChunkName: "component---src-pages-hire-warehouse-staff-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kitchen-staff-js": () => import("./../../../src/pages/kitchen-staff.js" /* webpackChunkName: "component---src-pages-kitchen-staff-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-pages-workinksa-js": () => import("./../../../src/pages/workinksa.js" /* webpackChunkName: "component---src-pages-workinksa-js" */),
  "component---src-pages-workinuae-js": () => import("./../../../src/pages/workinuae.js" /* webpackChunkName: "component---src-pages-workinuae-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog/index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-blog-main-page-index-js": () => import("./../../../src/templates/blogMainPage/index.js" /* webpackChunkName: "component---src-templates-blog-main-page-index-js" */),
  "component---src-templates-dynamic-page-index-js": () => import("./../../../src/templates/dynamicPage/index.js" /* webpackChunkName: "component---src-templates-dynamic-page-index-js" */)
}

