import React, { useEffect, useState } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'
import { useStaticQuery, graphql } from 'gatsby'
import { sprintf } from 'sprintf-js'
import { getResolvedLanguage } from '../../../services/get-language'

import './index.sass'
import { Link } from '../link'
import { defaultLanguageCode } from "../../../config/languages";

export const ChooseMode = ({ slug, country }) => {
  const [currentLanguage, setCurrentLanguage] = useState(defaultLanguageCode)
  const { t } = useTranslation()

  useEffect(() => {
    setCurrentLanguage(getResolvedLanguage())
  }, [])

  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx {
        nodes {
          frontmatter {
            slug
            country
            common_choose_mode_section {
              title
              sections {
                icon_image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                background_image {
                  childImageSharp {
                    gatsbyImageData
                    original {
                      src
                    }
                  }
                }
                background_color
                title
                subtitle
                button
                button_link
                text_color
                button_text_color
                button_color
                button_image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const chooseModeSection = allMdx.nodes.find(node => node.frontmatter.slug === slug && node.frontmatter.country === country).frontmatter.common_choose_mode_section

  return (
    <div className="choose-mode">
      <span className="title">{t(chooseModeSection.title)}</span>
      <div className="sections">
        {chooseModeSection.sections.map(section => (
          <Link
            key={section.title}
            to={sprintf(section.button_link, currentLanguage)}
            className="item"
            style={{ backgroundImage: `url(${section.background_image.childImageSharp.original.src})` }}
          >
            <div
              className="wrapper"
              style={{ backgroundColor: section.background_color }}
            >
              <div className="content">
                <GatsbyImage
                  image={getImage(section.icon_image)}
                  alt=""
                  className="icon"
                />
                <span className="title" style={{ color: section.text_color }}>
                  {t(section.title)}
                </span>
                <span className="subtitle" style={{ color: section.text_color }}>
                  {t(section.subtitle)}
                </span>
              </div>
              <div
                className="button"
                style={{ backgroundColor: section.button_color }}
              >
                <span
                  className="label"
                  style={{ color: section.button_text_color }}
                >
                  {t(section.button)}
                </span>
                <GatsbyImage
                  image={getImage(section.button_image)}
                  alt=""
                  className="arrow-img"
                />
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  )
}
