import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'
import { useStaticQuery, graphql } from 'gatsby'

import { Link } from '../../../components/common/link'
import { useHorizontalPaginator } from '../../../hooks/use-horizontal-paginator'
import { Paginator } from '../../../components/common/paginator'
import './index.sass'

export const DesignationServices = ({ slug, country }) => {
  const scrolledRef = React.useRef()
  const { t } = useTranslation()

  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx {
        nodes {
          frontmatter {
            slug
            country
            hire_services_section {
              title
              description
              list {
                service_image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                title
                professions
                button_title
                button_link
              }
            }
          }
        }
      }
    }
  `)

  const servicesSection = allMdx.nodes.find(node => node.frontmatter.slug === slug && node.frontmatter.country === country).frontmatter.hire_services_section
  const pageLength = (servicesSection.list.length % 3) + 1

  const { currentIndex, scrollHandler, scrollToIndex } = useHorizontalPaginator(pageLength, scrolledRef)

  return (
    <div className="services">
      <div className="service-header">
        <span className="title">{t(servicesSection.title)}</span>
        <span className="subtitle">{t(servicesSection.description)}</span>
      </div>
      <div className="service-list" onScroll={scrollHandler} ref={scrolledRef}>
        {servicesSection.list.map((serviceItem, serviceIndex) => (
          <div key={serviceItem.title} className={`service-item ${serviceIndex === 0 ? 'first' : ''}`}>
            <GatsbyImage
              image={getImage(serviceItem.service_image)}
              alt=""
              className="image"
            />
            <span className="title">{t(serviceItem.title)}</span>
            <ul className="list">
              {serviceItem.professions.map(profession => (
                <div key={profession} className="item">
                  {serviceIndex === 0 ? (
                    <StaticImage
                      src="../../../assets/common__checkmark-white.png"
                      alt=""
                      placeholder="blurred"
                      className="checkmark-img"
                    />
                  ) : (
                    <StaticImage
                        src="../../../assets/common__checkmark.png"
                      alt=""
                      placeholder="blurred"
                      className="checkmark-img"
                    />
                  )}
                  <span className="label">{t(profession)}</span>
                </div>
              ))}
            </ul>
            <Link className="button" to={serviceItem.button_link}>
              <span className="label">{t(serviceItem.button_title)}</span>
              {serviceIndex === 0 ? (
                <StaticImage
                  src="../../../assets/common__arrow-right.png"
                  alt=""
                  placeholder="blurred"
                  className="arrow-img"
                />
              ) : (
                <StaticImage
                  src="../../../assets/common__arrow-right-purple.png"
                  alt=""
                  placeholder="blurred"
                  className="arrow-img"
                />
              )}
            </Link>
          </div>
        ))}
      </div>
      <div className="pagination">
        <Paginator
          count={pageLength}
          navigateToIndex={scrollToIndex}
          activeIndex={currentIndex}
          listElementRef={scrolledRef}
        />
      </div>
    </div>
  )
}
