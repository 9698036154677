import React, { useContext } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import './index.sass'

import { AppContext } from '../../../layouts'

export const DesignationServicesList = ({ slug, country }) => {
  const { setGetStartedPopupVisible } = useContext(AppContext)
  const { t } = useTranslation()

  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx {
        nodes {
          frontmatter {
            country
            slug
            common_designation_services {
              title
              subtitle
              services
              button_title
              stats {
                title
                subtitle
                undertext
              }
              designation_image {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `)

  const designationSection = allMdx.nodes.find(node => node.frontmatter.slug === slug && node.frontmatter.country === country).frontmatter.common_designation_services
  const bgImg = designationSection.designation_image.childImageSharp.gatsbyImageData

  return (
    <div className="designation-services">
      <div className="first">
        <span className="title">{t(designationSection.title)}</span>
        <span className="subtitle">{t(designationSection.subtitle)}</span>
        <div className="list">
          {designationSection.services.map(service => (
            <div className="service" key={service}>
              <StaticImage
                src="../../../assets/common__checkmark.png"
                alt=""
                className="checkmark-img"
              />
              <span className="label">{t(service)}</span>
            </div>
          ))}
        </div>
        <div
          onClick={() => { setGetStartedPopupVisible(true) }}
          className="button"
        >
          <span className="label">{t(designationSection.button_title)}</span>
          <StaticImage
            src="../../../assets/common__arrow-right.png"
            alt=""
            className="arrow-img"
          />
        </div>
      </div>
      <div className="second">
        <GatsbyImage
          image={getImage(bgImg)}
          className="designation-image"
          alt=""
        />
        <div className="designation-stats">
          <span className="amount">{t(designationSection.stats.title)}</span>
          <span className="subtitle">{t(designationSection.stats.subtitle)}</span>
          {designationSection.stats.undertext && (
            <span className="undertext">{t(designationSection.stats.undertext)}</span>
          )}
        </div>
      </div>
    </div>
  )
}
