import React, {useContext, useState} from 'react'
import {GatsbyImage, getImage, StaticImage} from 'gatsby-plugin-image'

import { Link } from '../../link'
import './content.sass'
import {countryCodes} from "../../../../config/countries";
import {windowGlobal} from "../../../../utils/window-global";
import {AppContext} from "../../../../layouts";

const OPTION_CLIENT = 1
const OPTION_SP = 2

export function GetStartedContent({
  buttonsTitle,
  firstLinkAddress,
  firstButtonTitle,
  firstButtonDescription,
  secondButtonTitle,
  secondButtonDescription,
  secondLinkAddress,
  secondButtonBottomLink,
  storeLinks,
  popupTitle,
  popupSubtitle,
  qrTitle,
  qrDescription,
  signInTitle,
  signInDescription,
  getStarted
}) {
  const [chosenOption, setChosenOption] = useState(null)
  const [isDownloadAppMode, setIsDownloadAppMode] = useState(false)

  const { country: currentCountryCode } = useContext(AppContext)

  const trackMainButtonAnalyticsEvents = () => {
    const trackedGtagEvent = chosenOption === OPTION_CLIENT ? 'start_hiring_click' : 'start_working_click'
    const trackedFbqEvent = chosenOption === OPTION_CLIENT ? 'StartHiringClick' : 'StartWorkingClick'

    windowGlobal.gtag?.('event', trackedGtagEvent)
    if (window.location.pathname.includes('/hire')) {
      windowGlobal.fbq?.('trackCustom', trackedFbqEvent)
    }
  }

  return (
    <div className="get-started-content__container">
      {isDownloadAppMode ? (
          <div className="get-started-content__content">
            <div className="get-started-content__download-app">
              <div className="get-started-content__download-app__left">
                <span className="get-started-content__download-app__title">{popupTitle}</span>
                <span className="get-started-content__download-app__subtitle">{popupSubtitle}</span>
                {currentCountryCode === countryCodes.UAE ? (
                    <StaticImage
                        src="../../../../assets/download-uae-app-qr.png"
                        alt={qrTitle}
                        className="get-started-content__download-app__qr"
                    />
                ) : (
                    <StaticImage
                        src="../../../../assets/download-gr-app-qr.png"
                        alt={qrTitle}
                        className="get-started-content__download-app__qr"
                    />
                )}
                <span className="get-started-content__download-app__qr-description">{qrDescription}</span>
                <div className="get-started-content__download-app__stores">
                  {storeLinks.map(storeLinkItem => (
                      <Link
                          to={storeLinkItem.link}
                          key={storeLinkItem.title}
                          className="get-started-content__download-app__stores__img"
                      >
                        <GatsbyImage
                            image={getImage(storeLinkItem.store_image)}
                            alt={storeLinkItem.title}
                            className="get-started-content__download-app__stores__img"
                        />
                      </Link>
                  ))}
                </div>
              </div>
              <div className="get-started-content__download-app__right">
                {currentCountryCode === countryCodes.UAE ? (
                    <StaticImage src="../../../../assets/phone-rotated-uae.png" />
                ) : (
                    <StaticImage src="../../../../assets/phone-rotated-gr.png" />
                )}
              </div>
            </div>
          </div>
      ) : (
          <div className="get-started-content__content">
            <span className="title">{buttonsTitle}</span>
            <div className="buttons">
              <div onClick={() => setChosenOption(OPTION_SP)} className={`button${chosenOption === OPTION_SP ? ' active' : ''}`}>
                <div className="button__left">
                  <div className="button__left__filler"></div>
                </div>
                <div className="button__right">
                  <span className="button__title">{ firstButtonTitle }</span>
                  <span className="button__subtitle">{ firstButtonDescription }</span>
                </div>
              </div>
              <div onClick={() => setChosenOption(OPTION_CLIENT)} className={`button${chosenOption === OPTION_CLIENT ? ' active' : ''}`}>
                <div className="button__left">
                  <div className="button__left__filler"></div>
                </div>
                <div className="button__right">
                  <span className="button__title">{ secondButtonTitle }</span>
                  <span className="button__subtitle">{ secondButtonDescription }</span>
                </div>
              </div>
            </div>
            {chosenOption === null ? (
                <button className="submit" disabled>{getStarted}</button>
            ) : (
                <>
                  <button
                      className="submit mobile"
                      onClick={() => {
                        trackMainButtonAnalyticsEvents()

                        window.location.href = chosenOption === OPTION_CLIENT ? secondLinkAddress : firstLinkAddress
                      }}>{getStarted}</button>
                  <button
                      className="submit desktop"
                      onClick={() => {
                        trackMainButtonAnalyticsEvents()

                        if (chosenOption === OPTION_CLIENT) {
                          window.location.href = secondLinkAddress
                          return
                        }

                        setIsDownloadAppMode(true)
                      }}>{getStarted}</button>
                </>
            )}
            {chosenOption === OPTION_CLIENT && (
                <span className="link">{signInDescription} <Link
                    to={secondButtonBottomLink}
                    onClick={e => {
                      windowGlobal.gtag?.('event', 'client_sign_in_click')
                    }}
                >
                  {signInTitle}</Link></span>
            )}
          </div>
      )}
    </div>
  )
}