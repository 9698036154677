import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'
import { useStaticQuery, graphql } from 'gatsby'

import { Link } from '../../common/link'
import './index.sass'

export const WorkStoreWidget = ({ country }) => {
  const { t } = useTranslation()

  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx(filter: {frontmatter: {slug: {eq: "work"}}}) {
        nodes {
          frontmatter {
            country
            work_store_widget_section {
              title
              description
              store_links {
                title
                link
                store_image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              background_image {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const storeSection = allMdx.nodes.find(node => node.frontmatter.country === country).frontmatter.work_store_widget_section
  const bgImage = storeSection.background_image.childImageSharp.original.src

  return (
    <div
      className="store-widget"
      id="app-stores"
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <span className="title">{t(storeSection.title)}</span>
      <span className="description">{t(storeSection.description)}</span>
      <div className="stores">
        {storeSection.store_links.map(storeLinkItem => (
          <Link
            to={storeLinkItem.link}
            key={storeLinkItem.title}
            className="store-link"
          >
            <GatsbyImage
              image={getImage(storeLinkItem.store_image)}
              alt={storeLinkItem.title}
              className="store-img"
            />
          </Link>
        ))}
      </div>
    </div>
  )
}
