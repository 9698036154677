import React, { useContext } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'
import { StaticImage } from 'gatsby-plugin-image'

import { Header as PageHeader } from '../../common/header'
import { AppContext } from '../../../layouts'
import './index.sass'

export const HomeHeaderBase = ({ headerSection, linkColor, buttonColor, country }) => {
  const { setGetStartedPopupVisible } = useContext(AppContext)

  const { t } = useTranslation()

  const headerImgResource = headerSection.header_image
  const headerImage = getImage(headerImgResource)
  const headerImageSrc = headerImgResource.childImageSharp.original.src

  return (
    <div
      className="home-header"
      style={{ backgroundImage: `url(${headerImageSrc})` }}
    >
      <PageHeader linkColor={linkColor} buttonColor={buttonColor} country={country} />
      <div className="content">
        <div className="title-container">
          <div className="title-wrapper">
            <span className="title-label">
              {t(headerSection.title)}
              <StaticImage
                src="./assets/line.png"
                alt=""
                placeholder="blurred"
                className="line"
              />
            </span>
          </div>
        </div>
        <div className="subtitle-container">
          <span className="subtitle">{t(headerSection.subtitle)}</span>
        </div>
        <div
          onClick={() => { setGetStartedPopupVisible(true) }}
          className="button"
        >
          <span className="label">{t(headerSection.button_title)}</span>
          <StaticImage
            src="../../../assets/common__arrow-right.png"
            alt=""
            placeholder="blurred"
            className="arrow-img"
          />
        </div>
        <div className="stats-container-wrapper">
          <div className="stats-container">
            {headerSection.statistics.map(item => (
              <div className="item" key={item.title}>
                <span className="amount">{t(item.title)}</span>
                <span className="description">{t(item.subtitle)}</span>
              </div>
            ))}
          </div>
          {headerSection.statistics_undertext && (
            <span className="stats-undertext">{t(headerSection.statistics_undertext)}</span>
          )}
        </div>
      </div>
      <div className="mobile-header-image">
        <GatsbyImage
          image={headerImage}
          alt=""
          className="image"
          backgroundColor="transparent"
        />
      </div>
    </div>
  )
}
