import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import { GetStartedContent } from './content'
import './popup.sass'

export function GetStartedPopup({
  visible,
  onClose,
  buttonsTitle,
  firstLinkAddress,
  firstButtonTitle,
  firstButtonDescription,
  secondButtonTitle,
  secondButtonDescription,
  secondButtonBottomLink,
  storeLinks,
  popupTitle,
  popupSubtitle,
  qrTitle,
  qrDescription,
  signInTitle,
  signInDescription,
  getStarted,
  secondLinkAddress
}) {
  if (!visible) return null

  return (
    <div className="get-started-popup">
      <div onClick={onClose} className="close-img-container">
        <StaticImage
          src="../../../assets/common__close-button.png"
          alt="close"
          placeholder="blurred"
          className="close-img"
        />
      </div>
      <GetStartedContent
        buttonsTitle={buttonsTitle}
        firstLinkAddress={firstLinkAddress}
        firstButtonTitle={firstButtonTitle}
        firstButtonDescription={firstButtonDescription}
        secondButtonTitle={secondButtonTitle}
        secondButtonDescription={secondButtonDescription}
        secondButtonBottomLink={secondButtonBottomLink}
        storeLinks={storeLinks}
        popupTitle={popupTitle}
        popupSubtitle={popupSubtitle}
        secondLinkAddress={secondLinkAddress}
        qrTitle={qrTitle}
        qrDescription={qrDescription}
        signInTitle={signInTitle}
        signInDescription={signInDescription}
        getStarted={getStarted}
      />
    </div>
  )
}