import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import '../services/i18n'
import './index.sass'
import { useState } from 'react'
import { GetStarted } from '../components/common/get-started'
import { CountryPopup } from '../components/common/country-popup'
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { windowGlobal } from '../utils/window-global'
import { getResolvedLanguage } from "../services/get-language";
import { languageCodes } from "../config/languages";
import i18n from "../services/i18n";

export const AppContext = React.createContext(null)

export const Layout = ({ pageTitle, children, country = 'uae', language = 'en' }) => {
  const [getStartedPopupVisible, setGetStartedPopupVisible] = useState(false)
  const [countryPopupVisible, setCountryPopupVisible] = useState(false)

  i18n.changeLanguage(language)

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      allMdx(filter: {frontmatter: {type: {eq: "page"}}}) {
        nodes {
          frontmatter {
            country
            slug
          }
        }
      }
    }
  `)

  React.useEffect(() => {
    const isFirstVisit = localStorage.getItem('didSelectMarket') === null
    if (isFirstVisit && !windowGlobal.location.href.includes('sp-signup')) {
      setCountryPopupVisible(true)
    }
    if (windowGlobal.location.href.includes('/hire')) {
      windowGlobal.fbq?.('trackCustom', 'HirePageView')
    }
  }, [])

  const availablePages = React.useMemo(() => {
    return data.allMdx.nodes.reduce((acc, { frontmatter }) => {
      const { country, slug } = frontmatter

      return {
        ...acc,
        [country]: acc[country] ? [...acc[country], slug] : [slug]
      }
    }, {})
  }, [])

  return (
    <>
      <AppContext.Provider value={{ availablePages, getStartedPopupVisible, setGetStartedPopupVisible, country }}>
        <Helmet title={`${data.site.siteMetadata.title} - ${pageTitle}`}>
          <html dir={language === languageCodes.AR ? 'rtl' : 'ltr'} />
        </Helmet>
        <ToastContainer />
        {children}
        <GetStarted
          visible={getStartedPopupVisible}
          onClose={() => { setGetStartedPopupVisible(false) }}
          country={country}
        />
        <CountryPopup
          visible={countryPopupVisible}
          onClose={() => { setCountryPopupVisible(false) }}
          country={country}
          availablePages={availablePages}
        />
      </AppContext.Provider>
    </>
  )
}
