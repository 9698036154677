import React, {useContext} from 'react'
import { Link as GatsbyLink } from 'gatsby'

import i18n from '../../../services/i18n'
import { windowGlobal } from '../../../utils/window-global';
import {AppContext} from "../../../layouts";

export const Link = gatsbyLinkProps => {
  const { country: countryCode } = useContext(AppContext)

  const isExternalLink = gatsbyLinkProps.to.includes('http')

  const trackPageConversion = (ev) => {
    ev.preventDefault()
    if (gatsbyLinkProps.onClick) {
      gatsbyLinkProps.onClick()
    }
    windowGlobal.gtag?.('event', 'conversion_event_page_view', { page: gatsbyLinkProps.to })
  }

  if (isExternalLink) {
    return (
      <a
        href={gatsbyLinkProps.to}
        className={gatsbyLinkProps.className}
        style={gatsbyLinkProps.style}
        onClick={ev => {
          trackPageConversion(ev)
          if (gatsbyLinkProps.to.includes('https://url.ogram.co')) {
            windowGlobal.location = ev.currentTarget.getAttribute('href')
          } else {
            windowGlobal.open(gatsbyLinkProps.to, '_blank')
          }
        }}
      >
        {gatsbyLinkProps.children}
      </a>
    )
  }

  const userLanguage = i18n.resolvedLanguage
  const localisedLink = `/${countryCode}/${userLanguage}/${gatsbyLinkProps.to}`

  return (
    <GatsbyLink
      {...gatsbyLinkProps}
      to={localisedLink}
      onClick={(ev) => {
        trackPageConversion(ev)
        windowGlobal.location = ev.currentTarget.getAttribute('href')
      }}
    />
  )
}
