import React, { useState } from 'react'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'
import { useStaticQuery, graphql } from 'gatsby'

import { windowGlobal } from '../../../utils/window-global'
import './index.sass'

export const WorkReviews = ({country}) => {
  const { t } = useTranslation()

  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx(filter: {frontmatter: {slug: {eq: "work"}}}) {
        nodes {
          frontmatter {
            country
            work_reviews_section {
              title
              reviews {
                title
                author {
                  name
                  type
                  author_image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const [page, setPage] = useState(0)

  const reviewsSection = allMdx.nodes.find(node => node.frontmatter.country === country).frontmatter.work_reviews_section
  const isMobile = windowGlobal.innerWidth < 1200
  const reviewsPerPage = isMobile ? 1 : 2
  const totalPages = Math.ceil(reviewsSection.reviews.length / reviewsPerPage)
  const pageReviews = reviewsSection.reviews.slice(page * reviewsPerPage, reviewsPerPage + page * reviewsPerPage)

  const renderPagination = () => (
    <div className="paginator">
      <div
        onClick={() => {
          if (page === 0) {
            return
          }
          setPage(prevPageState => prevPageState - 1)
        }}
      >
        <StaticImage
          src="../../../assets/common__arrow-left-grey.png"
          alt=""
          className="image-back"
        />
      </div>
      <div
        onClick={() => {
          if (page === totalPages - 1) {
            return
          }
          setPage(prevPageState => prevPageState + 1)
        }}
      >
        <StaticImage
          src="../../../assets/common__arrow-right-black.png"
          alt=""
          className="image-next"
        />
      </div>
    </div>
  )

  return (
    <div className="work-reviews">
      <div className="reviews-header-container">
        <span className="title">{t(reviewsSection.title)}</span>
        <div className="background-quotes-container">
          <StaticImage
            src="./assets/quote.png"
            className="quotes-img"
            alt=""
          />
        </div>
        {renderPagination()}
      </div>
      <div className="reviews-list">
        {pageReviews.map(reviewItem => (
          <div key={reviewItem.author.name} className="review-item">
            <span className="title">{t(reviewItem.title)}</span>
            <div className="author-container">
              <GatsbyImage
                image={getImage(reviewItem.author.author_image)}
                className="image"
                alt=""
              />
              <div className="author-info">
                <span className="name">{t(reviewItem.author.name)}</span>
                <span className="type">{t(reviewItem.author.type)}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
      {renderPagination()}
    </div>
  )
}
