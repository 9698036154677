import React, { useContext } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { slide as Menu } from 'react-burger-menu'
import { useTranslation } from 'react-i18next'

import { Link } from '../../link'
import './burger-button.sass'
import { AppContext } from '../../../../layouts'
import { MarketLanguageSelector } from "../../market-selector/market-selector";
import {getResolvedLanguage} from "../../../../services/get-language";
import {languageCodes} from "../../../../config/languages";

export const BurgerButton = ({ links, getStartedButtonTitle, footerLabel, country }) => {
  const { setGetStartedPopupVisible } = useContext(AppContext)
  const { t } = useTranslation()

  return (
    <Menu
      right={getResolvedLanguage() !== languageCodes.AR}
      customCrossIcon={<StaticImage src="../../../../assets/common__hide-menu.png" alt="" />}
    >
      <div className="BurgerButton__MarketSelector">
        <MarketLanguageSelector textStyle={{ color: '#183B56' }} currentCountryCode={country} />
      </div>
      {links.map(linkItem => {
        if (linkItem.nested_links?.length > 0) {
          return (
            <div key={linkItem.title} className="submenu">
              <div className="title-container">
                <span className="title">
                  {t(linkItem.title)}
                </span>
                <StaticImage
                  src="../../../../assets/common__angle-down.png"
                  alt=""
                  className="angle-img"
                />
              </div>
              <div className="list">
                {linkItem.nested_links.map(nestedLink => (
                  <Link
                    className="mobile-link"
                    to={nestedLink.link}
                    key={nestedLink.title}
                    activeStyle={{ color: '#7767E4', fontWeight: 700 }}
                  >
                    {t(nestedLink.title)}
                  </Link>
                ))}
              </div>
            </div>
          )
        }
        return (
          <Link
            to={linkItem.link}
            key={linkItem.title}
            className="mobile-link"
            activeStyle={{ color: '#7767E4', fontWeight: 700 }}
          >
            {t(linkItem.title)}
          </Link>
        )
      })}
      {getStartedButtonTitle && (
        <div
          onClick={() => { setGetStartedPopupVisible(true) }}
          className="mobile-link-button primary"
        >
          <span className="mobile-link-button-label primary">
            {t(getStartedButtonTitle)}
          </span>
        </div>
      )}
      <div className="mobile-menu-footer">
        <StaticImage
          src="../../../../assets/common__heart.webp"
          alt=""
          placeholder="blurred"
          className="mobile-menu-footer-heart-img"
        />
        <span className="mobile-menu-footer-label">{t(footerLabel)}</span>
      </div>
    </Menu>
  )
}
