import React, { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'
import { Link } from '../../../common/link'
import { BurgerButton } from '../../../common/header/components/burger-button'
import './index.sass'
import { AppContext } from '../../../../layouts'
import { MarketLanguageSelector } from '../../../common/market-selector/market-selector'
import { countryCodes } from "../../../../config/countries";

export const WorkInUaeNav = ({ linkColor, country, buttonColor, withWhiteLogo, activeLinkColor }) => {
  const { setGetStartedPopupVisible } = useContext(AppContext)

  const { t } = useTranslation()

  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx(filter: {frontmatter: {slug: {regex: "/^workin/"}}}) {
        nodes {
          frontmatter {
            country
            slug
            work_nav_section {
              links {
                title
                link
              }
              start_button {
                title
              }
              footer_label
            }
          }
        }
      }
    }
  `)

  const nodeCountry = allMdx.nodes.find(node => node.frontmatter.country === country)
  const navSection = nodeCountry.frontmatter.work_nav_section

  return (
    <div className="header">
      <div className="logo-container">
        {withWhiteLogo ? (
          <StaticImage
            src="../../../assets/common__logo-white.png"
            alt="Ogram"
            placeholder="blurred"
            className="logo"
          />
        ) : (
          <StaticImage
            src="../../../assets/common__logo.png"
            alt="Ogram"
            placeholder="blurred"
            className="logo"
          />
        )}
      </div>
      <div className="burger-button">
        <BurgerButton
          links={navSection.links}
          footerLabel={navSection.footer_label}
          country={countryCodes.UAE}
        />
      </div>
      <nav className="navigation">
        {navSection.links.map(linkItem => {
          return (
            <Link
              to={linkItem.link}
              key={linkItem.title}
              activeStyle={{ color: activeLinkColor ? activeLinkColor : '#7767E4' }}
              style={{ color: linkColor }}
              className="link"
            >
              {t(linkItem.title)}
            </Link>
          )
        })}
        <MarketLanguageSelector textStyle={{ color: linkColor }} currentCountryCode={country} />
      </nav>
    </div>
  )
}
