import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'
import { useStaticQuery, graphql } from 'gatsby'

import { windowGlobal } from '../../../utils/window-global'
import { Header as PageHeader } from '../../common/header'
import './index.sass'

export const AboutHeader = ({ linkColor, buttonColor, country }) => {
  const { t } = useTranslation()

  const { allMdx } = useStaticQuery(graphql`
    query {
      allMdx(filter: {frontmatter: {slug: {eq: "about"}}}) {
        nodes {
          frontmatter {
            country
            about_header_section {
              title
              description
              description_mobile
              main_image_mobile {
                childImageSharp {
                  gatsbyImageData
                }
              }
              image_description_mobile
              benefit_list {
                item_image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                description
              }
            }
          }
        }
      }
    }
  `)

  const headerSection = allMdx.nodes.find(node => node.frontmatter.country === country).frontmatter.about_header_section
  const isMobile = windowGlobal.innerWidth < 1200
  const description = isMobile ? headerSection.description_mobile : headerSection.description

  return (
    <div className="about">
      <PageHeader linkColor={linkColor} buttonColor={buttonColor} country={country} />
      <div className="content">
        <span className="title">{t(headerSection.title)}</span>
        <span className="description">{t(description)}</span>
        <div
          className="image-container"
          style={{ display: isMobile ? 'block' : 'none' }}
        >
          {isMobile && (
            <GatsbyImage
              image={getImage(headerSection.main_image_mobile)}
              className="image"
              alt=""
            />
          )}
          <div className="text-container">
            <span className="text">{t(headerSection.image_description_mobile)}</span>
          </div>
        </div>
        <div className="benefit-list">
          {headerSection.benefit_list.map(benefitItem => (
            <div className="benefit-item" key={benefitItem.description}>
              <GatsbyImage
                image={getImage(benefitItem.item_image)}
                className="item-image"
                alt=""
              />
              <span className="item-description">{t(benefitItem.description)}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
