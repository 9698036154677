import {countryCodes} from "./countries";
import {windowGlobal} from "../utils/window-global";

const apiHosts = {
    [countryCodes.UAE]: 'https://api.ogram.co',
    [countryCodes.GREECE]: 'https://api-gr.ogram.co',
    [countryCodes.SA]: 'https://api-sa.ogram.co',
}

export const Settings = {
    apiHost: windowGlobal && !!localStorage.getItem('countryCode')
        ? apiHosts[localStorage.getItem('countryCode')]
        : apiHosts[countryCodes.UAE]
}